// For editing interactive pages
// import BitManager from '../../bm/BitManager.js';


class PageEditor {
    constructor ({ IB }) {
        this.displayName = 'Page Editor';
        this.IB = IB;

        this.currentBitKey;

        // this.bitManager = new BitManager();
        // this.bitManager.initBit('addBlock');
        // this.bits = this.bitManager.bits;
        this.bits = {
            'addBlock': this.IB.getBit('addBlock')
        };
    }

    // inject bit's uiNode into editor node
    handleClickBit = (bitKey) => {
        if (bitKey === this.currentBitKey) {
            // toggle when bit is re-clicked
            this.uiSetState((prevState) => ({ ...prevState, bitUiNode: null }));
            this.currentBitKey = null;
        } else {
            const bitUiNode = this.bits[bitKey].uiNode;
            this.uiSetState((prevState) => ({ ...prevState, bitUiNode }));
            this.currentBitKey = bitKey;
        }
    }
    
    uiNode = ({ nodeObject }) => {
        [this.uiState, this.uiSetState] = nodeObject.useState({
            bitUiNode: () => {}
        })        

        return (
            { name: "div", children: [
                Object.keys(this.bits).map((bitKey) => {
                    const bitName = this.bits[bitKey].bit.displayName;
                    return (
                        { name: 'button', props: { onClick: () => this.handleClickBit(bitKey), style: { padding: '4px' } }, children: bitName }
                    )
                }),
                { name: 'br' },
                { name: 'br' },
                { name: 'div', props: {}, children: this.uiState.bitUiNode }
            ] }
        )
    }
}


export default PageEditor;
// inter bit communication manager
import { Inbox } from '@mui/icons-material';
import { randomstring } from '../utils/general.js';
import IBObject from './IBObject.js';

class IBC {
    constructor () {

        this.ibObjects = {}
    }

    // create a new IB object
    initIB = ({ bitName }) => {
        
        const bitObject = ibx.bitManager.bits[bitName];
        
        const ibObjectId = `ibc-object-${bitName}-${randomstring(8, '0123456789')}`;
        const ibObject = new IBObject({ id: ibObjectId, bitName });
        
        // collect imported bit objects from bit manager
        // bitImports.forEach((importBitName) => {
        // ibObject.bitNameToObjects[importBitName] = ibx.bitManager.bits[importBitName];
        // });

        // attach a message queue to ibobject
        const messageQueueId = ibx.messageQueueManager.initMessageQueue({ ibObjectId });
        ibObject.messageQueueIdForImports = messageQueueId;

        this.ibObjects[ibObjectId] = ibObject;

        return ibObjectId;
    }
}

export default IBC;
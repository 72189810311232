import ContextObject from './ContextObject.js';


class ContextManager {
    constructor () {
        
        this.contexts = {};
    }
}

export default ContextManager;
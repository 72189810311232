import { randomstring, functionIsPromise } from '../utils/general.js';
import { getNodeType } from './utils.js';

// structures and actions for node, to be used by the node manager
class NodeObject {
    constructor (params) {
        // identity
        this.nodeId = params?.nodeId; // unique id of each node
        this.levelOrder = params?.levelOrder ?? []; // something like [0,1,2,2,...] that establishes the node's rendering order in the tree relative to other nodes at a particular level
        this.parentNodeId = params?.parentNodeId; // null for first node 
        this.childrenNodeIds = params?.childrenNodeIds ?? [];
        
        this.node = params?.node; // node
        this.nodeType; // array, function, object (name, props, children) 

        // browser host specific
        this.parentElementId = params?.parentElementId;  // establishes positioning within the html layout
        this.element; // this.element.levelOrder, this.element.props
        this.events = {};
        this.textNode;

        // lifecycle
        this.renderCount = 0; // increments by 1 after every render
        this.state; // json of parameters that when change, node re-renders
        this.setState = () => {}; // accessed using useState method
        this.nodeDidMount = () => {}; // Function after the node tree rendered for the first time. Executed in the node manager.
        this.nodeWillUnmount = () => {}; // Last function just before the entire node tree is destroyed. Executed in the node manager.
        
        // node manager object
        this.nodeManagerObject;
        

        // internal parameters
        this._maxRenders_ = 1000;
    }

    // ----------------------------------------------------------------------------------
    // host specific functions
    
    removeAndResetEvents = () => {
        Object.keys(this.events).forEach((event) => {
            this.removeEvent(event)
        })

        this.events = {};
    }

    removeAndResetElement = () => {
        // remove events before this as a good practice
        
        if (this.element) {
            this.element.remove();
        }

        this.element = null;
    }

    removeAndResetTextNode = () => {
        if (this.textNode) {
            this.textNode.remove();
        }

        this.textNode = null;
    }

    // deletes elements, events, objects related to this node only 
    reset = () => {
        // clean up host specific objects
        this.removeAndResetEvents();
        this.removeAndResetElement();
        this.removeAndResetTextNode();
    }

    isChildAfter = (referenceLevelOrder) => {
        const levelOrder = this.levelOrder;
        
        for (var i = 0; i < referenceLevelOrder; i++) {
            if (Number(levelOrder[i]) < Number(referenceLevelOrder[i])) {
                return false
            }
        }

        return true
    }

    insertInHostDom = (element) => {
        // insert this.element into this.parentElementId

        const parentElementId = this.parentElementId;
        const parentElement = document.getElementById(parentElementId);

        // check if parent has childrenIds
        let added = false;
        for (const child of Array.from(parentElement.childNodes)) {
            if (this.isChildAfter(child.levelOrder)) {
                continue
            } else {
                parentElement.insertBefore(element, child);
                added = true;
                break;
            }
        }

        if (!added) {
            parentElement.appendChild(element);
        }
    }

    removeEvent = (event) => {
        // Need to create
        const eventData = this.events[event];

        this.element.removeEventListener(eventData.event, eventData.handler);

        delete this.events[event];
    }

    addEvent = (event, handler) => {
        const element = this.element;

        if (Boolean(element)) {
            // element exists
            const eventData = this.events[event];

            if (eventData) {
                // If given handler is different, remove previous and add this one
                if (eventData.handler != handler) {
                    this.removeEvent(event);

                    // add fresh event and handler function
                    element.addEventListener(event, handler);
                    this.events[event] = handler;
                }
            } else {
                element.addEventListener(event, handler);
                this.events[event] = handler;
            }
        }
    }

    updateElementProps = () => {
        // update the this.node.props into this.element
        // relevent only for nodes that name an html element
        
        // update all the given props
        if (!this.node.props) {
            this.node.props = {};
        }
    
        const oldProps = this.element.props ?? {};
        const oldPropsKeys = Object.keys(oldProps);
        const newProps = this.node.props ?? {};
        const newPropsKeys = Object.keys(newProps);
        const newStyleKeys = newProps.style ? Object.keys(newProps.style) : [];

        // remove attributes, style props, events that are in old props but not in new props
        oldPropsKeys.forEach((key) => {
            if (key.slice(0,2) === 'on') {
                if (!newPropsKeys.includes(key)) {
                    // remove the event in props
                    var event = key.slice(2).toLowerCase();
                    this.removeEvent(event);
                }
            } else if (key === 'style') {
                for (let styleKey in oldProps.style) {
                    if (!newStyleKeys.includes(styleKey)) {
                        this.element.style[styleKey] = '';
                    }
                }
            } else {
                if (!newPropsKeys.includes(key)) {
                    element.removeAttribute(key);
                }
            }
        })

        // get or set the elementId
        if (!this.node.props.id) {
            // create an id 
            this.node.props.id = `ib-${this.node.name}-${randomstring(8, '0123456789')}`;    
        }

        // set props, start by setting id
        this.element.setAttribute('id', this.node.props.id);

        newPropsKeys.forEach((key) => {
            if (key.slice(0,2) === 'on') {
                // add an event
                var event = key.slice(2).toLowerCase();
                var handler = newProps[key];

                this.addEvent(event, handler);

            } else if (key === 'style') {
                for (let styleKey in newProps.style) {
                    this.element.style[styleKey] = newProps.style[styleKey];
                }
            } else {
                this.element.setAttribute(key, this.node.props[key]);
            }
        })

        // update props info in element
        this.element.props = newProps;
    }

    // ----------------------------------------------------------------------------------

    removeChildNodeId = (childNodeId) => {
        this.childrenNodeIds = this.childrenNodeIds.filter(item => item !== childNodeId)
    }

    addChildNodeId = (childNodeId) => {
        if (!this.childrenNodeIds.includes(childNodeId)) {
            this.childrenNodeIds.push(childNodeId)
        }
    }

    // renders or executes logic if present in the node and return the children nodes array
    renderNode = async () => {
        let childrenNodesArray = []; // to be calculated by the end of this function execution

        // don't render if max re-renders are achieved
        if (this.renderCount > this._maxRenders_) {
            console.warn(`Skipping re-rendering ${this.nodeId}. Maximum renders reached.`)
            return childrenNodesArray;
        }

        const nodeType = getNodeType(this.node);

        // if nodetype in the previous render is different we may need some cleanup
        const previousNodeType = this.nodeType;
        if (previousNodeType != nodeType) {
            if (previousNodeType === 'array') {
                // nothing to clean I suppose
            } else if (previousNodeType === 'function') {
                // nothing to clean I suppose
            } else if (nodeType === 'markup-function') {
                // nothing to clean I suppose
            } else if (nodeType === 'markup-name') {
                // clean up the elements because as of now name means element name
                this.removeAndResetEvents();
                this.removeAndResetElement();
            } else if (nodeType === 'string' || nodeType === 'number') {
                this.removeAndResetTextNode()
            }
        }

        // execute, render, cal children based on nodeType
        if (nodeType === 'array') {
            // nothing to execute just return children in an array
            childrenNodesArray = this.node;

        } else if (nodeType === 'function') {
            // run the function
            const result = this.node({ nodeObject: this });
            if (result instanceof Promise) {
                await result;
            }
            
            // get children array
            if (getNodeType(result) === 'array') {
                childrenNodesArray = result;
            } else if (getNodeType(result) != null) {
                childrenNodesArray = [ result ];
            }
        } else if (nodeType === 'markup-function') {
            const nodeFunction = this.node.function;
            const props = this.node.props ?? {};
            
            let children = [];
            if (getNodeType(this.node.children) === 'array') {
                children = this.node.children;
            } else if (getNodeType(this.node.children) != null) {
                children = [ this.node.children ];
            }

            const result = nodeFunction({ nodeObject: this, props, children });
            if (result instanceof Promise) {
                await result;
            }
            
            // get children array
            if (getNodeType(result) === 'array') {
                childrenNodesArray = result;
            } else if (getNodeType(result) != null) {
                childrenNodesArray = [ result ];
            }
        } else if (nodeType === 'markup-name') {
            // create the name element, insert at the right position in host dom, update props, calculate children
            if (this.element) {
                if (this.element.tagName.lowerCase() != this.node.name) {
                    // remove old element and associated events
                    this.removeAndResetEvents();
                    this.removeAndResetElement();

                    // create new element
                    this.element = document.createElement(this.node.name);
                    this.element.levelOrder = levelOrder;
                    this.insertInHostDom(this.element);
                } else {
                    // use the element already present as the name is same
                }
            } else {
                this.element = document.createElement(this.node.name);
                this.element.levelOrder = this.levelOrder;
                this.insertInHostDom(this.element);
            }
            
            // update with latest props - attributes, style, events
            this.updateElementProps()

            // get children array
            if (getNodeType(this.node?.children) === 'array') {
                childrenNodesArray = this.node.children;
            } else if (getNodeType(this.node?.children) != null) {
                childrenNodesArray = [ this.node.children ];
            }

        } else if (nodeType === 'string' || nodeType === 'number') {
            // this can be the nth render. If this render is 
            // create the textNode if it does not exist
            
            if (this.textNode) {
                // update old textnode
                this.textNode.textContent = this.node;
            } else {
                // create new textNode
                this.textNode = document.createTextNode(this.node);
                this.textNode.levelOrder = this.levelOrder;
                this.insertInHostDom(this.textNode);
            }
        } else {
            // unknown node type
        }
        
        // rendering part like creating, inserting element
        //  execution stuff for the node
        //  rendering stuff 

        // type of node - should be detected at the last moment. This will ensure conceptual identity of the node

        this.nodeType = nodeType; // update the node type

        this.renderCount += 1;

        return childrenNodesArray;
    }

    reRenderNode = async () => {
        // render node and then ask node manager to render the descendants
        const childrenNodesArray = await this.renderNode();
        await this.nodeManagerObject.renderChildrenNodeTrees({ nodeId: this.nodeId, childrenNodesArray });
    }

    useState = (__state__) => {
        if (this.state) {
            // this is a re-render
            
            return ([this.state, this.setState])
        } else {
            // this is a first time render
            
            const state = deepCopy(__state__);
    
            const setState = (__function__) => {
                const newState = __function__(state);
                
                // trigger render if any of the newState params changed.
                let triggerRender = false;
                Object.keys(state).map(key => {
                    if (state[key] != newState[key]) {
                        state[key] = newState[key]
                        triggerRender = true;
                    }
                })

                if (triggerRender) {
                    this.reRenderNode();   
                }
            }

            this.state = state;
            this.setState = setState; 

            return ([ state, setState ]);
        }
    }
}


export default NodeObject;
import MessageQueueObject from "./MessageQueueObject";
import { randomstring } from '../utils/general.js'

class MessageQueueManager {
    constructor () {

        this.messageQueues = {};
    }

    initMessageQueue = ({ ibObjectId }) => {
        const messageQueueId = `msgqueue-${randomstring(8, '0123456789')}`;
        const messageQueueObject = new MessageQueueObject({ id: messageQueueId });
        messageQueueObject.ibObjectId = ibObjectId;

        this.messageQueues[messageQueueId] = messageQueueObject;

        return messageQueueId;
    }
}

export default MessageQueueManager;
// node related utils

exports.getNodeType = (node) => {
    let type;
    
    if (Array.isArray(node)) {  
        type = 'array';
    } else if (typeof node === 'function') {
        type = 'function';
    } else if (typeof node === 'object') {
        if (node.name) {
            type = 'markup-name';
        } else if (node.function) {
            type = 'markup-function';
        }
    } else if (typeof node === 'string') {
        type = 'string'
    } else if (typeof node === 'number') {
        type = 'number'
    } else {
        // unknown type
    }

    return type;
}

// takes in stringified ib nodes and loads them dynamically into object
exports.stringNodeToObject = (stringNode, blockId) => {
    return new Promise(async (resolve, reject) => {
        // currently not handeling non function node here  

        // need the unique random sequence because systemjs do not re-loads the same name stringFunction even when all module traces are removed from System object as far as I know
        const randomSequence = ''; //Math.round(Math.random()*1e6);

        // wrapping stringNode into umd format
        // const code = `
        //     (function (root, factory) {
        //         if (typeof define === 'function' && define.amd) {
        //             // AMD environment
        //             define('stringFunction${blockId}${randomSequence}', [], factory);
        //         } else if (typeof module === 'object' && module.exports) {
        //             // CommonJS environment
        //             module.exports = factory();
        //         } else {
        //             // Browser global environment
        //             root.stringFunction${blockId}${randomSequence} = factory();
        //         }
        //     }(this, function () {
        //         const stringFunction${blockId}${randomSequence} = ${stringNode};
        //         return stringFunction${blockId}${randomSequence};
        //     }));
        // `

        const code = `
            (function (root) {
                const funcName = 'stringFunction${blockId}${randomSequence}';
                root[funcName] = ${stringNode};
            }(this));
        `;

        // Convert the string into a Blob
        const blob = new Blob([code], { type: 'application/javascript' });

        // Create a URL for the Blob
        const moduleUrl = URL.createObjectURL(blob);

        // Dynamically import the module
        let node = () => {};

        try {
            const script = document.createElement("script");
            script.src = moduleUrl;
            script.type = "text/javascript";
            script.onload = () => {
                // Access the globally registered function
                const funcName = `stringFunction${blockId}${randomSequence}`;
                node = window[funcName] ? window[funcName] : () => {};

                // Remove script after execution
                script.remove();

                resolve(node);
            };
            
            script.onerror = () => {
                reject(new Error(`Failed to load script: ${src}`))
            };

            document.body.appendChild(script); // trigger loading
        } catch (error) {
            console.error(error);
            resolve(node)
        }
    })
}
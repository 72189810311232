import BitObject from './BitObject.js';
// import IB from './IB.js';

// load bits into memory. Better way to do this in future
import AddBlock from '../../bits/addBlock/AddBlock.js';
import addBlockSettings from '../../bits/addBlock/settings.js';

import PageEditor from '../ui/pageeditor/PageEditor.js';
import pageEditorSettings from '../ui/pageeditor/settings.js';

const bitNameToClass = {
    'addBlock': AddBlock,
    'pageEditor': PageEditor
}

const bitNameToSettings = {
    'pageEditor': pageEditorSettings,
    'addBlock': addBlockSettings
}

const bitNameToPath = {
    'pageEditor': '/../src/core/ui/pageeditor',
}

class BitManager {
    constructor () {

        this.bits = {};
    }

    readBitSettings = async (bitName) => {
        const bitSettings = bitNameToSettings[bitName];

        return bitSettings;
    }


    initImportBit = async () => {

    }

    // instantiate bit object that can be used
    initBit = async (params) => {
        // get location where the bit is located
        // const bitPath = bitNameToPath[bitName];
        const bitName = params.bitName;

        // instantiate an empty bit object
        const bitObject = new BitObject({ bitName });

        // read the settings
        const bitSettings = await this.readBitSettings(bitName);
        
        // set bit imports
        let importBitNames = [];
        if (bitSettings.imports) {
            importBitNames = bitSettings.imports;
        }

        // instantiate bit imports and add to bitObject
        for (const importBitName of importBitNames) {
            // init import bit if not already init
            // in future different versions may be needed
            if (!this.bits[importBitName]) {
                await this.initBit({ bitName: importBitName, importedByBitName: bitName });
                
                // add the import bit's reference to bit object
                // bitObject.bitImportNameToObjects[importBitName] = this.bits[importBitName];
                bitObject.addImportBitName(importBitName);
            } else {
                bitObject.addImportBitName(importBitName);
            }
        }
        
        
        // create the ibc object for the bit to communicate
        const ibObjectId = ibx.ibc.initIB({ bitName })
        bitObject.ibObjectId = ibObjectId;

        // instantiate bit entry class
        const BitEntryClass = bitNameToClass[bitName];
        if (BitEntryClass) {
            bitObject.setBit(BitEntryClass);
            this.bits[bitName] = bitObject;
        }
    }
}


export default BitManager;
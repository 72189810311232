import React from 'react'
import ReactDOM from 'react-dom/client'
import Routes from './Routes.jsx'

// ------------------------------------------------------
// init core
import NodeManager from './core/nm/NodeManager.js';
import BitManager from './core/bm/BitManager.js';
import ContextManager from './core/context/ContextManager.js';
import MessageQueueManager from './core/message/MessageQueueManager.js';
import IBC from './core/bm/IBC.js';

window.ibx = {
    nodeManager: new NodeManager(),
    bitManager: new BitManager(),
    contextManager: new ContextManager(),
    messageQueueManager: new MessageQueueManager(),
    ibc: new IBC()
}

// ------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<Routes />)
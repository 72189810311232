
// wasm manager loads the wasm module, say addBlock.wasm 
// imports IB through which this wasm module talks to bit manager

// imports nodeManager using IB
// blocks are imported, like Text, Box, Column, Button, VerticalSpacer using IB

// sets the display name of this module as Add Block, when clicked, will show a ui node
// sets the ui node of this module using IB.

// ui node will show buttons with block names. When a button, say Text, is clicked, handleClickBlock runs
// handleClickBlock usses IB api to connect to page editor.
// Then it requests page editor, via IB, to add this block.



// const handleClickBlock = ({ blockName }) => {}

// const node = { "name": "div", "children": "hello world" };

// send this node to IB

// Ideally, the bit must be solely execution
class AddBlock {
    constructor ({ IB }) {
        this.displayName = 'Add Block';
        this.IB = IB;
    }

    handleClickNode = (nodeName) => {
        // for now, lets just say that we need to send this node to pageEditor to display
        // const node = ({ nodeObject, children }) => {
        //     return (
        //         { name: 'div', children: 'this is text node' }
        //     )
        // }

        // send this node to pageeditor to display - edit and read modes
        this.IB.sendMessage({ to: 'importedByBit', event: 'addNode', data: { nodeName } });
    }

    uiNode = () => {

        return (
            { name: 'div', children: [
                { name: 'button', props: { onClick: () => this.handleClickNode('Text') }, children: 'Text' },
                { name: 'button', children: 'VerticalSpacer' },
                { name: 'button', children: 'Image' },
                { name: 'button', children: 'Video' },
                { name: 'button', children: 'Columns' }
            ] }
        )
    }

}

export default AddBlock;
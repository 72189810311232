



class MessageQueueObject {
    constructor ({ id }) {
        this.id = id;
        this.ibObjectId;
        this.messages = {};
    }

    newMessage = (message) => {
        // add new message to inbox
        const messageId = Object.keys(this.messages).length
        this.messages[messageId] = message;

        // trigger the bitobject to read the latest message
        this.ibObjectId.newMessageReceived({ messageId });
    }

    deleteMessage = (messageId) => {
        delete this.messages[messageId];
    }
}

export default MessageQueueObject;
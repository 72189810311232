// interface with apis using which bit talks to bit manager.
// part of inter bit communication

class IBObject {
    constructor ({ id, bitName }) {
        this.id = id;
        // this.bitNameToObjects = {};
        // this.bitImports = bitImports;
        this.bitName = bitName; // bit that communicates using this object

        this.messageQueueIdForImports; // queueId to talk to imports
    }

    // message event 
    sendMessage = (message) => {
        // need to wrap the message into a message queue id so that 
        // the other bit can access this message
     
        if (message.to == 'importedByBit') {
            const myBitObject = ibx.bitManager.bits[this.bitName];
            const importedByBitObject = ibx.bitManager.bits[myBitObject.importedByBitName];
            const importedByBitMessageQueueId = ibx.ibc.isObjects[importedByBitObject.ibObjectId].messageQueueIdForImports;
            const importedByBitMessageQueueObject = ibx.messageQueueManager.messageQueues[importedByBitMessageQueueId];
            
            const _message = { ...message, senderBitName: this.bitName }
            importedByBitMessageQueueObject.newMessage(_message)
        }

        // this.messageQueueObject.
        
    }

    // trigger that a new message is received
    newMessageReceived = ({ messageId }) => {
        // read the message from the queue object
        const messageQueueObject = ibx.messageQueueManager.messageQueues[this.messageQueueIdForImports];
        
        // read the latest message
        const message = messageQueueObject.messages[messageId];

        // do something with the message
        

        // delete the message
        messageQueueObject.deleteMessage(messageId)
    }

    // return the bit instance from what's available
    getBit = (bitName) => {
        if (ibx.bitManager.bits[this.bitName].importBitNames.includes(bitName)) {
            return ibx.bitManager.bits[bitName];
        }
    }
    
}

export default IBObject;
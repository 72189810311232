// import IB from './IB.js';

import { Inbox } from "@mui/icons-material";



class BitObject {
    constructor (params) {
        this.importedByBitName = params?.importedByBitName;
        this.name = params?.bitName;
        this.importBitNames = params?.importBitNames ?? [];
        this.uiNode;
        this.bit;
        this.ibObjectId;

        // bit object should have the references to its imports
        // this.bitImportNameToObjects = {};
    }

    // Instantiates the entry point class as provided by the settings of the bit
    setBit = (BitClass) => {
        // ask ibx.ibc to create IB object for this bit
        // const ibObject = ibx.ibc.initIB({ bitName: this.name })

        this.bit = new BitClass({ IB: this.ibObject });
        this.uiNode = this.bit.uiNode;
    }

    addImportBitName = (importBitName) => {
        if (!this.importBitNames.includes(importBitName)) {
            this.importBitNames.push(importBitName);
        }
    }

    removeImportBitName = (importBitName) => {
        this.importBitNames = this.importBitNames.filter(item => item !== importBitName);
    }

}


export default BitObject;
import { randomstring, functionIsPromise } from '../utils/general.js';
import { getNodeType } from './utils.js';
import NodeObject from './NodeObject.js';

// host specific actions and structures to be used by the manager
class Host {
    constructor (params) {

        this.view = document; // content object in browser host environment

        this.elements = {}; // manages elements created by node manager and hold their props like element id

        // node name to object dictionary. Helps to use html elements as markup nodes which has name, props, children format
        this.nameDictionary = {
            "div": ({children, props}) => <div {...props}>{children}</div>,
            "b": ({children}) => <b>{children}</b>,
            "i": ({children}) => <i>{children}</i>,
            "u": ({children}) => <u>{children}</u>,
            "br": () => <br />,
            "span": ({children}) => <span>{children}</span>,
            "a": ({children, props}) => <a {...props}>{children}</a>,
        }
    }

    
}

const host = new Host(); 




// component is always a function that declares that it is a component
// componentManager instantiates when a browser window is reloaded, creating a new context
// Node manager things 
// createNode, destroyNode, renderNode, trigger node to take an action
class NodeManager {
    constructor() {
        // this.nodes[nodeId] is a nodeObject that contains the node and its properties
        this.nodes = {};
    }

    componentDidMount = (__function__, componentId) => {
        this.components[componentId].componentDidMount = __function__;
    }

    componentWillUnmount = () => {}

    useState = (__state__, nodeId) => {
        if (this.nodes[nodeId]?.state) {
            // this is a re-render
            
            return ([this.nodes[nodeId].state, this.nodes[nodeId].setState])
        } else {
            // this is a first time render

            const state = deepCopy(__state__);
    
            const setState = (__function__) => {
                const newState = __function__(state);
                let triggerRender = false;
                Object.keys(state).map(key => {
                    if (state[key] != newState[key]) {
                        state[key] = newState[key]
                        triggerRender = true
                    }
                })
                if (triggerRender) {
                    this.renderNode(nodeId)
                    // const myDiv = document.getElementById('bits-component');
                    // myDiv.setAttribute('data-key', myDiv.getAttribute('data-key')+1);
                    // myDiv.style.backgroundColor = 'gray'
                    // console.log(Object.getOwnPropertyNames(myDiv))
                    // Object.getOwnPropertyNames(myDiv).map(prop => {
                    //     console.log(prop)
                    // })
                }
            }

            this.nodes[nodeId].state = state;
            this.nodes[nodeId].setState = setState; 

            return ([ state, setState ]);
        }
    }
    


    addEvent = (nodeId, eventName, elementId, event, handler) => {
        const element = document.getElementById(elementId);

        if (Boolean(element)) {
            // element exists

            if (this.nodes[nodeId].events?.[eventName]?.added) {
                // If given handler is different, remove previous and add this one
                if (this.nodes[nodeId].events[eventName].handler != handler) {
                    element.removeEventListener(event, this.nodes[nodeId].events[eventName].handler);
                    this.nodes[nodeId].events[eventName].handler = handler;
                    element.addEventListener(this.nodes[nodeId].events[eventName].event, this.nodes[nodeId].events[eventName].handler);
                }
            } else {
                if (!Boolean(this.nodes[nodeId].events)) {
                    this.nodes[nodeId].events = {};
                }
    
                if (!Boolean(this.nodes[nodeId].events[eventName])) {
                    this.nodes[nodeId].events[eventName] = {
                        elementId, 
                        event, 
                        handler,
                        added: false
                    }
                }
    
                element.addEventListener(this.nodes[nodeId].events[eventName].event, this.nodes[nodeId].events[eventName].handler);
                this.nodes[nodeId].events[eventName].added = true;
            }
            
        }
    }

    removeEvent = (nodeId, eventName) => {
        // Need to create
        const eventData = this.nodes[nodeId].events[eventName]

        const element = document.getElementById(eventData.elementId);
        element.removeEventListener(eventData.event, eventData.handler);
        this.nodes[nodeId].events[eventName].added = false;
    }

    destroyNode = (nodeId) => {
        // pretty sure destroying node needs to optimally do garbage collection
        // also, right now this destroy only element type nodes

        // simply return if no nodeId exists, maybe the node is already deleted
        if (!this.nodes[nodeId]) {
            return
        }

        const nodeObject = this.nodes[nodeId];
        const node = nodeObject.node;

        // first destroy any children 
        if (nodeObject.childNodeId) {
            this.destroyNode(nodeObject.childNodeId)
        }

        if (nodeObject.childrenNodeIds) {
            nodeObject.childrenNodeIds.forEach((childNodeId) => this.destroyNode(childNodeId));
        }

        // remove events if they exist
        if (nodeObject.events) {    
            Object.keys(nodeObject.events).forEach((eventName) => {
                this.removeEvent(nodeId, eventName)
            })
        }

        // delete the element if exists
        if (nodeObject.element) { 
            nodeObject.element.remove();
            
        }

        // delete any textnode
        if (nodeObject.textNode) {
            var element = document.getElementById(nodeObject.parentElementId);
            if (element) {
                element.removeChild(nodeObject.textNode);
            }
        }

        // finally delete the object key
        delete this.nodes[nodeId];
    } 

    destroyNodeTree = async (nodeId) => {
        // completely remove any trace nodeId, its node and recursively all descendents

        if (!this.nodes[nodeId]) {
            return
        }

        // before destroying run the last function if provided
        var result = this.nodes[nodeId].nodeWillUnmount();
        if (result instanceof Promise) {
            await result; // Wait for the promise to resolve
        }

        const nodeObject = this.nodes[nodeId];
        
        // first destroy any children 
        if (nodeObject.childrenNodeIds) {
            nodeObject.childrenNodeIds.forEach((childNodeId) => this.destroyNodeTree(childNodeId));
        }

        // remove nodeId from parentNodeId's childrenNodeIds
        if (this.nodes[nodeObject.parentNodeId]) {
            this.nodes[nodeObject.parentNodeId].removeChildNodeId(nodeId);
        }

        // remove any host specific definitions and objects
        nodeObject.reset();

        // finally remove the node entry
        delete this.nodes[nodeId];
    }

    nodeType = (node) => {
        let type;
        if (Array.isArray(node)) {  
            type = 'array';
        } else if (typeof node === 'function') {
            type = 'function';
        } else if (typeof node === 'object') {
            type = 'object';
        }

        return type;
    }

    isChildIndexAfter = (childIndex, referenceChildIndex) => {
        const childIndices = childIndex.split('-');
        const referenceChildIndices = referenceChildIndex.split('-');
        
        for (var i = 0; i < referenceChildIndices.length; i++) {
            if (Number(childIndices[i]) < Number(referenceChildIndices[i])) {
                return false
            }
        }
        return true
    }

    // insert child html element at the correct position
    insertChildElement = (childElement, nodeId) => {
        const nodeObject = this.nodes[nodeId];
        const parentElementId = nodeObject.parentElementId;
        const parentElement = document.getElementById(parentElementId);

        // check if parent has childrenIds
        let added = false;
        for (const child of Array.from(parentElement.childNodes)) {
            if (this.isChildIndexAfter(childElement.myChildIndex, child.myChildIndex)) {
                continue
            } else {
                parentElement.insertBefore(childElement, child);        
                added = true;
                break;
            }
        }

        if (!added) {
            parentElement.appendChild(childElement);
        }
        

        // if (nodeObject.myChildIndex) {

        // } else {
        //     // childIndex does not exist. Probably a 
        //     parentElement.appendChild(childElement);

        //     if (this.elements[parentElementId]) {

        //     } else {
        //         this.elements[parentElementId] = 
        //     }
        // }
    }

    removeElement = (element, nodeId) => {
        // const parentElementId = this.nodes[nodeId].parentElementId;
        // const parentElement = document.getElementById(parentElementId);

        // parentElement.removeChild(element)
        element.remove();

        // // update element tree
        // if (this.elements[parentElementId]) {
        //     this.elements[parentElementId].push(childElement);
        // } else {
        //     this.elements[parentElementId] = [ childElement ];
        // }
    }
    
    renderNode = (nodeId) => {
        // A node is an elementObject, component function, regular function, 
        // TODO: When nodes are compared, the previous and the new node is taken to be of the same type- array, object or function; which may not be the case.
        // So a more elegant node -> newNode update is required.
        
        let nodeObject = this.nodes[nodeId];
        let node = nodeObject.node;
        let parentElementId = nodeObject.parentElementId;
        
        // if node is null, destroy it just to be safe
        if (nodeObject.node === null) {
            this.destroyNode(nodeId);
        }
        
        // If newNode is of a different type, destroy old and create new.
        if (typeof nodeObject.newNode != 'undefined') {
            
            if (nodeObject.newNode === null) {
                this.destroyNode(nodeId);
                return
            } else if (this.nodeType(nodeObject.newNode) != this.nodeType(node)) {
                
                const newNode = nodeObject.newNode;
                const parentNodeId = nodeObject.parentNodeId;
                const myChildIndex = nodeObject.myChildIndex;

                this.destroyNode(nodeId);
                
                // create a fresh nodeId. Let's not because then this child will be lost for its parentNode's childrenNodeIds if any.
                // nodeId = `ib-node-${randomstring(8, '0123456789')}`;

                this.nodes[nodeId] = {
                    myNodeId: nodeId,
                    myChildIndex,
                    node: newNode,
                    parentElementId,
                    parentNodeId
                }
    
                nodeObject = this.nodes[nodeId];
                node = nodeObject.node;
                parentElementId = nodeObject.parentElementId;
            }
        }

        if (Array.isArray(node)) {
            
            // go through each new node
            if (nodeObject.newNode) {
                nodeObject.newNode.forEach((childNode, index) => {
                    let childNodeId;
                    let childMyChildIndex = `${nodeObject.myChildIndex}-${index}`;
                    if (nodeObject.childrenNodeIds[index]) {
                        childNodeId = nodeObject.childrenNodeIds[index];
                        if (this.nodes[childNodeId]) {
                            this.nodes[childNodeId].newNode = childNode;
                        } else {
                            this.nodes[childNodeId] = {
                                myNodeId: childNodeId,
                                myChildIndex: childMyChildIndex,
                                node: childNode,
                                parentElementId,
                                parentNodeId: nodeId
                            }    
                        }
                        // console.log('**', index, this.nodes[childNodeId].node,'----', this.nodes[childNodeId].newNode)
                        this.renderNode(childNodeId);
                    } else {
                        childNodeId = `ib-node-${randomstring(8, '0123456789')}`;
                        this.nodes[childNodeId] = {
                            myNodeId: childNodeId,
                            myChildIndex: childMyChildIndex,
                            node: childNode,
                            parentElementId,
                            parentNodeId: nodeId
                        }
                        nodeObject.childrenNodeIds.push(childNodeId);
                        this.renderNode(childNodeId);    
                    }
                })

                // if nodeObject.childrenNodeIds are more than length of newNode.children, destroy extra nodes
                
                if (nodeObject.childrenNodeIds.length > nodeObject.newNode.length) {
                    for (let i = nodeObject.newNode.length; i < nodeObject.childrenNodeIds.length; i++) {
                        this.destroyNode(nodeObject.childrenNodeIds[i]);
                    }
                    nodeObject.childrenNodeIds.slice(0, nodeObject.newNode.length);
                }
            } else {
                nodeObject.childrenNodeIds = [];
                node.forEach((childNode, index) => {
                    const childNodeId = `ib-node-${randomstring(8, '0123456789')}`;
                    let childMyChildIndex = `${nodeObject.myChildIndex}-${index}`;
                    this.nodes[childNodeId] = {
                        myNodeId: childNodeId,
                        myChildIndex: childMyChildIndex,
                        node: childNode,
                        parentElementId,
                        parentNodeId: nodeId
                    }
                    
                    nodeObject.childrenNodeIds.push(childNodeId);
                    this.renderNode(childNodeId);
                })
            }
        } else if (typeof node === 'function') {
            // key is just a unique identifier for the returned node within a function
            // we reset the returned child to null before executed the function so as to know if the anything is returned at all
            nodeObject.returnedChildKey = null; 
            nodeObject.returnedChildNode = null; 
        
            // add return attribute if doesn't exists
            if (!Boolean(nodeObject.return)) {
                nodeObject.return = (key, childNode) => { 
                    nodeObject.returnedChildKey = key;
                    nodeObject.returnedChildNode = childNode;
                }
            }
            
            const processFunctionReturn = () => {
                if (nodeObject.returnedChildKey) {
                    
                    if (nodeObject.lastReturnedChildKey) {
                        if (nodeObject.lastReturnedChildKey == nodeObject.returnedChildKey) {
                            // same child key as previous render so do nothing
                            // but the child may still be different due to variables update
                            // so attach a newChild too
        
                            this.nodes[nodeObject.childNodeId].newNode = nodeObject.returnedChildNode;
                        } else {
                            // new childNode is returned which means 
                            
                            // the previous child needs to be destroyed, and
                            this.destroyNode(nodeObject.childNodeId);

                            // new child is added to be rendered
                            const childNodeId = `ib-node-${randomstring(8, '0123456789')}`;
                            this.nodes[childNodeId] = {
                                myNodeId: childNodeId,
                                myChildIndex: nodeObject.myChildIndex,
                                node: nodeObject.returnedChildNode,
                                parentElementId,
                                parentNodeId: nodeId
                            };
                            nodeObject.childNodeId = childNodeId;
                            nodeObject.lastReturnedChildKey = nodeObject.returnedChildKey;    
                        }
                    } else {
                        // no child node exists yet, add the given childNode 
                        const childNodeId = `ib-node-${randomstring(8, '0123456789')}`;
                        this.nodes[childNodeId] = {
                            myNodeId: childNodeId,
                            myChildIndex: nodeObject.myChildIndex,
                            node: nodeObject.returnedChildNode,
                            parentElementId,
                            parentNodeId: nodeId
                        };
                        nodeObject.childNodeId = childNodeId;
                        nodeObject.lastReturnedChildKey = nodeObject.returnedChildKey;
                    }

                    // render the set child node id
                    
                    this.renderNode(nodeObject.childNodeId)
                } else {
                    // nothing is returned this time, so destroy the lastReturned childnode if any
                    if (nodeObject.childNodeId) {
                        this.destroyNode(nodeObject.childNodeId);
                        nodeObject.childNodeId = null;
                        nodeObject.lastReturnedChildKey = null;
                    }
                }
            }

            
            
                
            // Since this node is a component function, we need to run it and feed it its node object
            // but components are given lifecycles so determine if its mounted
            if (nodeObject.mounted) {
                // this is a re-render of this component node
                
                if (nodeObject.newNode) {
                    node = nodeObject.newNode;
                }
                
                // run the function
                const childNode = node({nodeObject});
                
                if (functionIsPromise(childNode)) {
                    childNode.then(childNode => {
                        if (Boolean(childNode)) {
                            nodeObject.return('0', childNode);
                        }
        
                        // process return of the function
                        processFunctionReturn();
                    })
                } else {
                    if (Boolean(childNode)) {
                        nodeObject.return('0', childNode);
                    }
    
                    // process return of the function
                    processFunctionReturn();    
                }
                
            } else {
                // first time render

                // give nodeObject state hooks
                
                nodeObject.useState = (__state__) => this.useState(__state__, nodeId);

                // run the function
                const childNode = node({nodeObject});
                
                if (functionIsPromise(childNode)) {
                    childNode.then(childNode => {
                        if (Boolean(childNode)) {
                            nodeObject.return('0', childNode);
                        }
        
                        // process return of the function
                        processFunctionReturn();
                        
                        // function ran, meaning component is mounted
                        nodeObject.mounted = true;
        
                        // run componentDidMount if exists
                        if (nodeObject.componentDidMount) {
                            nodeObject.componentDidMount()
                        }    
                    })
                } else {
                    if (Boolean(childNode)) {
                        nodeObject.return('0', childNode);
                    }
    
                    // process return of the function
                    processFunctionReturn();
                    
                    // function ran, meaning component is mounted
                    nodeObject.mounted = true;
    
                    // run componentDidMount if exists
                    if (nodeObject.componentDidMount) {
                        nodeObject.componentDidMount()
                    }
                }
            }
            
        } else if (typeof node === 'object' && node !== null) {

            if (Boolean(node.function)) {
                
                if (nodeObject.newNode) {
                    // if new node exists we can just replace the old node with new node
                    nodeObject.node = nodeObject.newNode
                } 
                
                if (nodeObject.childNodeId) {
                    // child node is already present
                    const childNode = ({ nodeObject }) => node.function({ nodeObject, props: node.props, children: node.children });

                    nodeObject.newNode = childNode;

                    this.renderNode(nodeObject.childNodeId);
                } else {
                    // node.function becomes the childNode
                    
                    const childNode = ({ nodeObject }) => node.function({ nodeObject, props: node.props, children: node.children });

                    if (Boolean(childNode)) {
                        const childNodeId = `ib-node-${randomstring(8, '0123456789')}`;
                        this.nodes[childNodeId] = {
                            myNodeId: childNodeId,
                            myChildIndex: nodeObject.myChildIndex,
                            node: childNode,
                            parentElementId,
                            parentNodeId: nodeId
                        };
                        
                        nodeObject.childNodeId = childNodeId;
                        this.renderNode(childNodeId);
                    }   
                }
            } else if (Boolean(node.name)) {
                const parentElement = document.getElementById(parentElementId);

                // use already-created element or create a new one
                let element;
    
                if (Boolean(nodeObject.newNode) && nodeObject.newNode.name != node.name) {
                    // newNode has a different element. Completely replace node with newNode
                    // parentElement.removeChild(nodeObject.element)

                    const newNode = nodeObject.newNode;
                    const parentNodeId = nodeObject.parentNodeId;
                    const myChildIndex = nodeObject.myChildIndex;

                    this.destroyNode(nodeId)

                    this.nodes[nodeId] = {
                        myNodeId: nodeId,
                        myChildIndex,
                        node: newNode,
                        parentElementId,
                        parentNodeId
                    }

                    nodeObject = this.nodes[nodeId];
                    node = nodeObject.node;
                    parentElementId = nodeObject.parentElementId;

                    // node.name = nodeObject.newNode.name;
                    element = document.createElement(node.name);
                    element.myChildIndex = nodeObject.myChildIndex;
                    this.insertChildElement(element, nodeId);
                    nodeObject.element = element;

                } else {
                    if (nodeObject.element) {
                        element = nodeObject.element;
                    } else {
                        element = document.createElement(node.name);
                        element.myChildIndex = nodeObject.myChildIndex;    
                        this.insertChildElement(element, nodeId);
                        nodeObject.element = element;
                    }   
                }

                // update all the given props
                if (!node.props) {
                    node.props = {}
                }

                if (nodeObject.newNode) {
                    const newProps = nodeObject.newNode.props ?? {};
                    const newPropsKeys = Object.keys(newProps);
                    const newStyleKeys = newProps.style ? Object.keys(newProps.style) : [];
                    
                    // remove attributes and style props that are in props but not in new props
                    Object.keys(node.props).map((key) => {
                        if (key.slice(0,2) === 'on') {
                            if (!newPropsKeys.includes(key)) {
                                // remove the event in props
                            }
                        }
                        if (key === 'style') {
                            for (let styleKey in node.props.style) {
                                if (!newStyleKeys.includes(styleKey)) {
                                    element.style[styleKey] = '';
                                }
                            }
                        } else {
                            if (!newPropsKeys.includes(key)) {
                                element.removeAttribute(key);
                            }
                        }
                    })

                    // replace props with new props
                    node.props = newProps;
                }

                // get or set the elementId
                if (node.props.id) {
                    nodeObject.elementId = node.props.id;
                } else if (!element.id) {
                    // create an id 
                    nodeObject.elementId = `ib-${node.name}-${randomstring(8, '0123456789')}`;    
                }

                // set props, start by settign id
                element.setAttribute('id', nodeObject.elementId);

                if (node.props) {
                    Object.keys(node.props).map((key) => {
                        if (key.slice(0,2) === 'on') {
                            // add an event
                            var elementId = nodeObject.elementId;
                            var event = key.slice(2).toLowerCase();
                            var eventName = nodeObject.elementId + event;
                            var handler = node.props[key];
                            
                            this.addEvent(nodeId, eventName, elementId, event, handler);

                        } else if (key === 'style') {
                            for (let styleKey in node.props.style) {
                                element.style[styleKey] = node.props.style[styleKey];
                            }
                        } else {
                            element.setAttribute(key, node.props[key]);
                        }
                    })
                }

                // let elementId = null;
                // if (element.id) {
                //     elementId = element.id;
                // } else {
                //     elementId = `ib-${node.name}-${randomstring(8, '0123456789')}`;
                //     element.id = elementId
                // }

                
                
                
                
                if (nodeObject.childNodeId) {
                    // child node is present just render it
                    
                    if (nodeObject.newNode) {
                        this.nodes[nodeObject.childNodeId].newNode = nodeObject.newNode.children;
                    }
                    
                    this.renderNode(nodeObject.childNodeId);
                } else {
                    if (node.children != null) {
                        // create a childNode for the children

                        const childNodeId = `ib-node-${randomstring(8, '0123456789')}`;
                        this.nodes[childNodeId] = {
                            myNodeId: childNodeId,
                            myChildIndex: nodeObject.myChildIndex,
                            node: node.children,
                            parentElementId: nodeObject.elementId,
                            parentNodeId: nodeId
                        };
                        
                        nodeObject.childNodeId = childNodeId;
                        this.renderNode(childNodeId);
                    } else {
                        // TODO: how to deal with this scenerio.
                    }
                }
            }
            
        } else if (typeof node === 'string' || typeof node === 'number') {
            const parentElement = document.getElementById(parentElementId);

            if (typeof nodeObject.newNode === 'undefined') {
                // this is a fresh textNode. Render it.

                const textNode = document.createTextNode(nodeObject.node);
                textNode.myChildIndex = nodeObject.myChildIndex;

                this.insertChildElement(textNode, nodeId);
                
                nodeObject.textNode = textNode;
            } else {
                // newNode exists
                
                if (nodeObject.newNode === null) {
                    parentElement.removeChild(nodeObject.textNode);
                    nodeObject.node = nodeObject.newNode;

                } else if (nodeObject.newNode != nodeObject.node) {
                    // console.log(nodeObject.newNode, node, nodeObject.textNode)

                    // create the textNode if it does not exist
                    if (nodeObject.textNode) {
                        // update old textnode
                        nodeObject.textNode.textContent = nodeObject.newNode;
                    } else {
                        
                        const textNode = document.createTextNode(nodeObject.newNode);
                        textNode.myChildIndex = nodeObject.myChildIndex;
                        // parentElement.appendChild(textNode);
                        this.insertChildElement(textNode, nodeId);
                        nodeObject.textNode = textNode;
                    }

                    // replace the node with newNode
                    nodeObject.node = nodeObject.newNode;   
                }
            }
        }
        
    }

    initNewNode = (params) => {
        // create a fresh unique node id
        if (!params) {
            params = {};
        }
        
        const nodeId = params?.nodeId ? params.nodeId : `ib-node-${randomstring(8, '0123456789')}`;
  
        params.nodeId = nodeId;

        // empty Node instance for all node related stuff
        const nodeObject = new NodeObject(params);

        // record node entry
        this.nodes[nodeId] = nodeObject;

        // add node manager so that nodeObject can talk to nodeManager
        nodeObject.nodeManagerObject = this;

        // if parent node if is given, add to parent's children if necessary
        if (params.parentNodeId) {
            if (this.nodes[params.parentNodeId]) {
                if (!this.nodes[params.parentNodeId].childrenNodeIds.includes(nodeId)) {
                    this.nodes[params.parentNodeId].addChildNodeId(nodeId);
                }
            }
        }

        return nodeId;
    }

    // render array of nodes as children for a nodeId as parent
    renderChildrenNodeTrees = async ({ nodeId, childrenNodesArray }) => {
        const nodeObject = this.nodes[nodeId];

        const newChildrenNodesArray = childrenNodesArray;
        const oldChildrenNodeIds = nodeObject.childrenNodeIds;

        // identify new child Ids, init nodeId if needed
        const newChildrenNodeIds = [];
        for (let i = 0; i < newChildrenNodesArray.length; i++) {
            let childNodeId;
            if (!oldChildrenNodeIds[i]) {
                // create node
                // update parentElementId if node has an element
                const parentElementId = this.nodes[nodeId].element?.id ?? this.nodes[nodeId].parentElementId;

                const params = {
                    levelOrder: [...this.nodes[nodeId].levelOrder, i],
                    parentNodeId: nodeId,
                    parentElementId,
                }
                childNodeId = this.initNewNode(params);
            } else {
                childNodeId = oldChildrenNodeIds[i];
            }
            
            newChildrenNodeIds.push(childNodeId);
        }

        // update childrenNodeIds
        nodeObject.childrenNodeIds = newChildrenNodeIds;

        // now render each new child node tree
        for (let i = 0; i < newChildrenNodesArray.length; i++) {
            const childNodeId = newChildrenNodeIds[i];
            const newChildNode = newChildrenNodesArray[i];
            await this.renderNodeTree({ nodeId: childNodeId, newNode: newChildNode });
        }

        // destroy extra nodes in oldChildrenNodeIds, if any.
        if (oldChildrenNodeIds.length > newChildrenNodesArray.length) {
            const numExtra = oldChildrenNodeIds.length - newChildrenNodesArray.length;
            for (let i = 0; i < numExtra; i++) {
                const oldNodeId = oldChildrenNodeIds[newChildrenNodesArray.length + i];
                await this.destroyNodeTree(oldNodeId);
            }
        }
    }

    // works on the recursive unit of tree, which is a node and its children array.
    // nodeId represents the rendered version
    // newNode is to be updated in nodeId, properly
    // the name of this function should be updateNodeIdWithNewNode
    renderNodeTree = async ({ nodeId, newNode }) => {
        
        if (newNode === null || newNode === undefined || getNodeType(newNode) === null) {
            // no newNode received to fill into the nodeId, so do nothing
            
            // // render the original node
            // const nodeObject = this.nodes[nodeId];
            // const newChildrenNodesArray = await nodeObject.renderNode();

            // const oldChildrenNodeIds = nodeObject.childrenNodeIds;

            // // go through children array and render 
            // for (let i=0; i<=newChildrenNodesArray.length; i++) {
            //     const childNodeId = oldChildrenNodeIds[i];
            //     const newChildNode = newChildrenNodesArray[i];
            //     await this.renderNodeTree({ childNodeId, newChildNode });
            // }

            // // destroy extra nodes in oldChildrenNodeIds, if any.
            // // create extra nodes in newChildrenNodesArray, if any.
        } else if (this.nodes[nodeId].node === null || this.nodes[nodeId].node === undefined) {
            
            // render the new node
            const nodeObject = this.nodes[nodeId];
            
            nodeObject.node = newNode;
            const childrenNodesArray = await nodeObject.renderNode();

            await this.renderChildrenNodeTrees({ nodeId, childrenNodesArray })
        } else {
            
            // if nodetype of newnode is different from this.nodes[nodeId].node 
            // destroy oldNode and create newNode
            // if (this.nodeType2(newNode) != this.nodeType2(this.nodes[nodeId].node)) {
                
                // copy node's properties
                const params = {
                    nodeId,
                    levelOrder: this.nodes[nodeId].levelOrder,
                    parentNodeId: this.nodes[nodeId].parentNodeId,
                    childrenNodeIds: [], // children will be created fresh
                    parentElementId: this.nodes[nodeId].parentElementId,
                }

                // remove node completely including the nodeid entry and parent elements
                await this.destroyNodeTree(nodeId);

                // create new node instance with the same nodeId and parameters
                this.initNewNode(params);

                // fresh render
                await this.renderNodeTree({ nodeId, newNode });

            // } else {
            //     // reconcile newNode with the old one for each data type

            // }
        }

        // if this is the first render of nodeid, run nodeDidMount before anything 
        if (this.nodes[nodeId].renderCount === 1) { 
            const result = this.nodes[nodeId].nodeDidMount();
            if (result instanceof Promise) {
                await result;
            }
        }


        // const nodeObject = this.nodes[nodeId];
        // const childrenNodesArray = await nodeObject.renderNode(); // 

        // now nodeObject may have childrenIds, need to compare this with childrenNodesArray,
        
        // Compare these children one child at a time. 
        // Extra nodes in nodeObject.childrenNodeIds are destroyed.
        // Extra nodes in childrenNodesArray are created.

        // first check if the node type is different, if yes, then destroy old and create new node.
        
        // If the node type is same, then we have a new node and an old node. New is compared with old. 
        // Point of comparison is to reconcile new with old. After reconciliation, we should know if 
        // old is to be destroyed, new to be ignored, or old updated with new updates.
        // For array, there is no execution, it just returns the children. Now for two arrays, we have two children sets.
        // For function, the newNode needs to be executed, then the children are returned
        // For markup, again the name-function is checked if different, if yes, then old is destroyed and new is created, children are returned
        // For markup, if function is same, then new Function is evaluated anyway and children are returned
        // For markup, if name is same, then props are updated, children are returned
        // For markup, if name is same and of a function node (block), then the function is fetched from the nodeDictionary and executed, children are returned.
        
        // The point of 

    }

    

    initRootNode = (node, parentElementId) => {
        // create a fresh unique node id
        const nodeId = this.initNewNode();
        
        const nodeObject = this.nodes[nodeId];

        nodeObject.parentElementId = parentElementId;
        
        // // instantiate fresh node
        // const nodeObject = new Node({ 
        //     myNodeId: nodeId, // I can just call this nodeId
        //     myChildIndex: '0', // treeCoordinates seems more apt
        //     node,
        //     parentElementId
        // });
        
        // // add node to manager's array
        // this.nodes[nodeId] = nodeObject;

        // // add node to list
        // this.nodes[nodeId] = {
        //     myNodeId: nodeId,
        //     myChildIndex: '0',
        //     node,
        //     parentElementId,  // establishes positioning within the html layout
        //     parentNodeId: null, // first node 
        //     childrenNodeIds: []
        // };

        // this.renderNode(nodeId);

        // nodeObject.renderNode();

        this.renderNodeTree({ nodeId, newNode: node });
    }
}

export default NodeManager;